import main from './mainConfig'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

window.onerror = (message, source, lineno, colno, error) => {
    // window.alert(`${message} => ${source}:${lineno}`)
}
let mainData = new main({router, store, render: h => {
    console.log(h)
    return h(App)
}})
// main.router = router
// main.store = store
// main.render = h => h(App)

new Vue(mainData.options).$mount("#app");

// export default {
// 	router,
// 	store,
// 	render: (h) => h(App),
// 	methods: {
// 		textSet(to) {
// 			let {name} = to
// 			let {TEXT} = this
// 			TEXT = TEXT || {}
// 			let list = TEXT[name] || {}
// 			Vue.prototype.pageText = list
// 		},
// 		routerInit(){
			
// 		},
// 		routeBeforeEach(to, from){
// 			this.textSet(to)
// 			return true
// 		},
// 		async afterEach(){
// 		}
// 	},
// 	created() {
// 		Vue.prototype._routeHand = new routeHand({
// 			router, 
// 			route: this.$route, 
// 			routeList: this._routeList, 
// 			beforeEach: this.routeBeforeEach, 
// 			afterEach: this.afterEach,
// 			nextTick: this.$nextTick
// 		})
// 		if(this._request){
// 			this._request._routeHand = Vue.prototype._routeHand
// 		}
// 	},
// 	update(){
// 	},
// 	mounted(){

// 	},
// 	watch: {
// 		$route: {
// 			handler(val, oldVal){
// 				if(!this.pageText || !Object.keys(this.pageText).length) this.textSet(val)
// 				if(this._routeHand){
// 					this._routeHand.setRoute(this.$route)
// 					let to = val, from = oldVal
// 					this._routeHand.eachEmit({to, from, type: 'after'})
// 				}
// 			},
// 			deep: true,
// 			immediate: true
// 		}
// 	}
// }
